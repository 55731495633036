<template>
  <div id="app" onselectstart="return false;">
    <transition
      name="change"
      mode="out-in"
      enter-active-class="animate__animated animate__zoomIn"
    >
      <router-view v-if="isRouterAlive"></router-view>
    </transition>
  </div>
</template>

<script>
export default {
  name: "App",
  provide() {
    //父组件中通过provide来提供变量，在子组件中通过inject来注入变量。
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      userName: "",
      passWord: "",
      MenuPosition: false,
      isRouterAlive: true, //控制视图是否显示的变量
    };
  },
  components: {},
  watch: {
    $route(to, from) {
      // 判断是否由登录页进入（是的话map页面项目选择默认为所以项目）
      if (to.path == "/map" && from.path == "/Login") {
        sessionStorage.setItem("pathskip", 1);
      } else {
        sessionStorage.setItem("pathskip", 2);
      }
    },
  },
  methods: {
    reload() {
      this.isRouterAlive = false; //先关闭，
      this.$nextTick(function () {
        this.isRouterAlive = true; //再打开
      });
    },
  },
};
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
}

// 二级菜单距离一级菜单的padding
.el-menu-item-group__title {
  padding: 0px 0 0px 20px !important;
}
// 取消select滚动条
select::-webkit-scrollbar {
  display: none !important;
}
/* 隐藏地图水印 */
.amap-logo {
  right: 0 !important;
  left: auto !important;
  display: none !important;
}
.amap-copyright {
  right: 0px !important;
  left: auto !important;
  visibility: hidden;
  display: none !important;
}
.amap-copyright {
  opacity: 0 !important;
  display: none !important;
}
// 解决高德地图闪白框
.anchorBL {
  display: none !important;
}
.amap-contaniner {
  background-color: transparent !important;
  // background-color: #131626 !important;
}
/* 解决浏览器记住密码白框 */
input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  -webkit-text-fill-color: #ededed !important;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
  background-color: transparent;
  background-image: none;
  transition: background-color 50000s ease-in-out 0s;
}
</style>
